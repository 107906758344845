jQuery(document).ready(function($){

	$( '.js-button-primary, .js-menu-primary-close' ).click( function( event ){

		event.preventDefault();

		//$('.mobile-menu').toggleClass("is-expanded");
		$( '.js-menu-primary' ).toggleClass( 'is-active' );
		$( '.c-menu-overlay' ).toggleClass( 'is-active' );

	});

	/**
	 * Close menu and remove overlay when overlay is clicked.
	 */
	$( '.c-menu-overlay' ).click( function( event ){

		event.preventDefault();

		$( '.js-menu-primary' ).removeClass( 'is-active' );
		$( '.c-menu-overlay' ).removeClass( 'is-active' );

	});


});
